import { useState, useEffect } from "react";

export default function Success() {
  useEffect(() => {
    // * CONFETTI ANIMATION
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div class="flex items-center justify-center h-screen">
      <div>
        <div class="flex flex-col items-center space-y-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="text-green-600 w-28 h-28"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h1 class="text-4xl font-bold">
            Bedankt dat je de cursus hebt aangeschaft!
          </h1>
          <p>
            <ul>
              <li>Bekijk je e-mail voor verdere cursus instructies. </li>

              <li>
                Je ontvangt binnen een uur een e-mail van Huddle Community.{" "}
              </li>

              <li>
                Log in en je kunt gelijk aan de slag met de cursus op Huddle.{" "}
              </li>

              <li>Je kunt ook de app Huddle downloaden via de app store. </li>
            </ul>
          </p>
          <a
            href="/"
            class="h-10 px-5 text-white bg-gradient-to-r bg-kasiagreen-default hover:bg-kasiayellow-default hover:text-white py-2 px-4 rounded mr-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-3 h-3 mr-2 inline-block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M7 16l-4-4m0 0l4-4m-4 4h18"
              />
            </svg>
            <span class="text-sm font-medium">Home</span>
          </a>
        </div>
      </div>
    </div>
  );
}
