import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import { PortableText } from "@portabletext/react";
import Vimeo from "@u-wave/react-vimeo";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const Googlemaps = () => {
  const [allPostsData, setAllPosts] = useState(null);

  const components = {
    types: {
      image: (props) => (
        <figure>
          <img src={urlFor(props.value.asset).width(200).url()} />
        </figure>
      ),
      h1: ({ props }) => <h1 className="text-2xl">{props}</h1>,
    },
    block: {
      // Ex. 1: customizing common block types
      h1: ({ children }) => <h1 className="text-2xl">{children}</h1>,
      h2: ({ children }) => (
        <h2 className="text-2xl md:text-3xl py-4 leading-snug text-gray-800 dark:text-gray-200 md:text-4xl">
          {children}
        </h2>
      ),
      blockquote: ({ children }) => (
        <blockquote className="border-l-purple-500">{children}</blockquote>
      ),

      // Ex. 2: rendering custom styles
      customHeading: ({ children }) => (
        <h2 className="text-3xl leading-snug text-gray-800 dark:text-gray-200 md:text-4xl">
          {children}
        </h2>
      ),
    },
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "locatie"]{
        title,
        body,
        slug,
        text,
        image,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  return (
    <section className="containergooglemaps">
      {allPostsData &&
        allPostsData.map((locatie, index) => (
          <span key={index}>
            <div className="container px-4 py-10 mx-auto lg:h-128 lg:space-x-8 lg:flex lg:items-center">
              <div
                ref={ref}
                className={`box w-full text-center lg:text-left lg:w-1/2 lg:-mt-8 ${
                  inView ? "actives" : ""
                }`}
              >
                {locatie.mainImage && (
                  <img
                    src={urlFor(locatie.mainImage).width(2048).url()}
                    alt={locatie.title}
                  />
                )}

                {/*  <div className="google-map-code">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2452.3213178164706!2d4.33445581582365!3d52.073880376946505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5b70384e83c97%3A0x91a2cf6d7ecb4cbe!2sBinckhorstlaan%2036%2C%202516%20BE%20Den%20Haag!5e0!3m2!1snl!2snl!4v1660945892483!5m2!1snl!2snl"
            width="600"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
  </div> */}
              </div>
              <div
                ref={ref}
                className={`box w-full text-center lg:text-left lg:w-1/2 lg:-mt-8 ${
                  inView ? "actives" : ""
                }`}
              >
                <span key={index}>
                  <PortableText value={locatie.text} components={components} />
                  <span>
                    <p className="mt-4 text-lg text-gray-500 dark:text-gray-300">
                      {locatie.body}
                    </p>
                  </span>
                </span>
                <div className="embed-container">
                  <Vimeo video="766144233" width="640" height="564" />
                </div>
              </div>
            </div>
          </span>
        ))}
    </section>
  );
};
export { Googlemaps };
