import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

export default function Allblocks() {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        body,
        slug,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div>
      <section className="container px-4 py-10 mx-auto lg:h-128 lg:space-x-8 lg:flex lg:items-center">
        <div>
          {allPostsData &&
            allPostsData.map((post, index) => (
              //<Link to={"/" + post.slug.current} key={post.slug.current}>
              <span key={index}>
                {post.mainImage && (
                  <img
                    src={urlFor(post.mainImage).width(200).url()}
                    className="w-10 h-10 rounded-full"
                    alt={post.title}
                  />
                )}

                <span>
                  <p>{post.body}</p>
                </span>

                <span>
                  <h2 className="title">{post.title}</h2>
                </span>
              </span>
              // </Link>
            ))}
        </div>
      </section>
    </div>
  );
}
