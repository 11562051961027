import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import { PortableText } from "@portabletext/react";
import doc from "./doc.json";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

export default function Allsblock() {
  const [allPostsData, setAllPosts] = useState(null);

  const components = {
    types: {
      image: (props) => (
        <figure>
          <img src={urlFor(props.value.asset).width(200).url()} />
        </figure>
      ),
    },
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "klip"]{
        title,
        body,
        slug,
        text,
        image,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div>
      <section className="container px-4 py-10 mx-auto lg:h-128 lg:space-x-8 lg:flex lg:items-center">
        <div>
          {allPostsData &&
            allPostsData.map((klip, index) => (
              // <Link to={"/" + klip.slug.current} key={klip.slug.current}>
              <span key={index}>
                {klip.mainImage && (
                  <img
                    src={urlFor(klip.mainImage).width(200).url()}
                    className="w-10 h-10"
                    alt={klip.title}
                  />
                )}

                <PortableText value={klip.text} components={components} />
                <span>
                  <p>{klip.body}</p>
                </span>

                <span>
                  <h2>{klip.title}</h2>
                </span>
              </span>
              //  </Link>
            ))}
        </div>
      </section>
    </div>
  );
}
