import React from "react";
import Vimeo from "@u-wave/react-vimeo"; // eslint-disable-line import/no-unresolved
import Vimeosanity from "./Vimeosanity";

const videos = [
  { id: 763721608, name: "Wie is Kasia Kijewska?" },
  { id: 763725387, name: "Wat doe ik als logopedist?" },
  { id: 766144233, name: "Waar is de praktijk?" },
];

class Vimeos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoIndex: 0,
      volume: 1,
      paused: false,
    };

    this.handlePause = this.handlePause.bind(this);
    this.handlePlayerPause = this.handlePlayerPause.bind(this);
    this.handlePlayerPlay = this.handlePlayerPlay.bind(this);
    this.handleVolume = this.handleVolume.bind(this);
  }

  handlePause(event) {
    this.setState({
      paused: event.target.checked,
    });
  }

  handlePlayerPause() {
    this.setState({ paused: true });
  }

  handlePlayerPlay() {
    this.setState({ paused: false });
  }

  handleVolume(event) {
    this.setState({
      volume: parseFloat(event.target.value),
    });
  }

  selectVideo(index) {
    this.setState({ videoIndex: index });
  }

  render() {
    const { videoIndex, paused, volume } = this.state;

    const video = videos[videoIndex];
    return (
      <section className="containervimeo">
        <div className="container px-4 py-10 mx-auto lg:h-128 lg:space-x-8 lg:flex lg:items-center">
          <Vimeosanity />
          <div className="w-full text-center lg:text-left lg:w-1/2 lg:-mt-8">
            <div className="row rowx">
              <div className="col s4">
                <div className="collection grid sm:grid-cols-3 gap-3">
                  {videos.map((choice, index) => (
                    <button
                      key={index}
                      href={`#!/video/${index}`}
                      className={`w-full text-white bg-kasiagreen-default hover:bg-kasiayellow-default font-semibold hover:text-white py-2 px-4 rounded mr-2 videotItem contactItem__video collection-item ${
                        video === choice ? "active" : ""
                      }`}
                      onClick={() => this.selectVideo(index)}
                    >
                      {choice.name}
                    </button>
                  ))}
                </div>
                {/*  <h5>Paused</h5>
              <p>
                <label htmlFor="paused">
                  <input
                    type="checkbox"
                    id="paused"
                    checked={paused}
                    onChange={this.handlePause}
                  />
                  <span>Paused</span>
                </label>
              </p>
              <h5>Volume</h5>
              <input
                type="range"
                value={volume}
                min={0}
                max={1}
                step={0.01}
                onChange={this.handleVolume}
                  />*/}
              </div>

              <div className="col s8 center-align">
                <Vimeo
                  video={video.id}
                  width={640}
                  height={480}
                  volume={volume}
                  paused={paused}
                  onPause={this.handlePlayerPause}
                  onPlay={this.handlePlayerPlay}
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Vimeos;
