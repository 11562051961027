import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import Fpartone from "./footerpart/Fpartone";
import Fparttwo from "./footerpart/Fparttwo";
import Fpartthree from "./footerpart/Fpartthree";
import Fpartfour from "./footerpart/Fpartfour";

const Footer = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  /*
  function logit() {
    // setScrollY(window.pageYOffset);

    const div = document.querySelectorAll(".box");
    div.forEach((entry) => {
      const rect = entry.getBoundingClientRect().y;
      if (rect < -1) {
        entry.classList.add("mystyles");
      } else {
        entry.classList.remove("mystyles");
      }
    });
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });
*/
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="text-center mb-20">
          <Fpartone />
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div className="p-4  w-full md:w-1/3 flex flex-col text-center items-center">
            <Fparttwo />
          </div>
          <div className="p-4 w-full md:w-1/3 flex flex-col text-center items-center">
            <Fpartthree />
          </div>
          <div className="p-4 w-full md:w-1/3 flex flex-col text-center items-center">
            <Fpartfour />
          </div>
        </div>
      </div>
    </section>
  );
};
export { Footer };

{
  /*
    <section class="text-gray-600 body-font">
      <div class="container px-5 py-24 mx-auto">
        <div class="text-center mb-20">
          <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
            Raw Denim Heirloom Man Braid
          </h1>
          <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
            Blue bottle crucifix vinyl post-ironic four dollar toast vegan
            taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi
            pug.
          </p>
          <div class="flex mt-6 justify-center">
            <div class="w-16 h-1 rounded-full bg-indigo-500 inline-flex"></div>
          </div>
        </div>
        <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div class="color1 w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
              <img className="mailme" src={mail} width="50px" />
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                Mail mij
              </h2>
              <p class="leading-relaxed text-base">info@logopeda.nl</p>
              <a class="mt-3 text-indigo-500 inline-flex items-center">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div class="color1 w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
              <img className="callme" src={call} width="50px" />
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                Bel mij
              </h2>
              <p class="leading-relaxed text-base">
                Telefoonnummer: 06-14734642
              </p>
              <a class="mt-3 text-indigo-500 inline-flex items-center">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div class="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div class="color1 w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
              <img className="mapme" src={map} width="70px" />
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                Adres
              </h2>
              <p class="leading-relaxed text-base">
                Binckhorstlaan 36 2516 BE Den Haag Magazijn 3de verdieping,
                Kamer 46
              </p>
              <a class="mt-3 text-indigo-500 inline-flex items-center">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
  </section>*/
}
