import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import All from "./components/All.js";
import Success from "./components/success.js";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<All />} />
          <Route path="*" element={<All />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}
export default App;
