import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import Heroone from "./heropart/heropartone";
import Herotwo from "./heropart/heroparttwo";
import Herothree from "./heropart/heropartthree";

function Hero() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 1000);
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  if (!loading) {
    return <div></div>;
  }

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto flex flex-wrap">
        <div className="lg:w-2/3 mx-auto">
          <Heroone />
          <div className="flex flex-wrap -mx-2">
            <div className="px-2 w-1/2">
              <Herotwo />
            </div>
            <div className="px-2 w-1/2">
              <Herothree />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
