import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import { PortableText } from "@portabletext/react";
import { useInView } from "react-intersection-observer";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const Vimeosanity = (props) => {
  const [allPostsData, setAllPosts] = useState(null);

  const components = {
    types: {
      image: (props) => (
        <figure>
          <img src={urlFor(props.value.asset).width(200).url()} />
        </figure>
      ),
      h1: ({ props }) => <h1 className="text-2xl">{props}</h1>,
    },
    block: {
      // Ex. 1: customizing common block types
      h1: ({ children }) => <h1 className="text-2xl">{children}</h1>,
      h2: ({ children }) => (
        <h2 className="text-3xl leading-snug text-white dark:text-gray-200 md:text-4xl">
          {children}
        </h2>
      ),
      blockquote: ({ children }) => (
        <blockquote className="border-l-purple-500">{children}</blockquote>
      ),

      // Ex. 2: rendering custom styles
      customHeading: ({ children }) => (
        <h2 className="text-3xl leading-snug text-gray-800 dark:text-gray-200 md:text-4xl">
          {children}
        </h2>
      ),
    },
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "vimeo"]{
        title,
        body,
        slug,
        text,
        image,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <React.Fragment>
      <div
        ref={ref}
        className={`box w-full text-center lg:text-left lg:w-1/2 lg:-mt-8 ${
          inView ? "actives" : ""
        }`}
      >
        {allPostsData &&
          allPostsData.map((vimeo, index) => (
            // <Link to={"/" + klip.slug.current} key={klip.slug.current}>
            <span key={index}>
              {vimeo.mainImage && (
                <img
                  src={urlFor(vimeo.mainImage).width(200).url()}
                  className="w-10 h-10"
                  alt={vimeo.title}
                />
              )}

              <PortableText value={vimeo.text} components={components} />
              <span>
                <p className="mt-4 text-lg text-white dark:text-gray-300">
                  {vimeo.body}
                </p>
              </span>
            </span>
            //  </Link>
          ))}
      </div>
    </React.Fragment>
  );
};

export default Vimeosanity;
