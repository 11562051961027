import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import { PortableText } from "@portabletext/react";
import doc from "./doc.json";
import fideo from "../assets/image/Logopeda_adv_02-V2mp4.mp4";
import ReactPlayer from "react-player";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

export default function Allsblock() {
  const [allPostsData, setAllPosts] = useState(null);

  const components = {
    types: {
      image: (props) => (
        <figure>
          <img src={urlFor(props.value.asset).width(400).url()} />
        </figure>
      ),
      h1: ({ props }) => <h1 className="text-2xl">{props}</h1>,
    },
    block: {
      // Ex. 1: customizing common block types
      h1: ({ children }) => <h1 className="text-5xl leading-6">{children}</h1>,
      h2: ({ children }) => (
        <h2 className="text-3xl text-white leading-snug leading-6 dark:text-gray-900 md:text-4xl">
          {children}
        </h2>
      ),
      normal: ({ children }) => (
        <p className="mt-4 text-lg leading-6 text-gray-900 dark:text-gray-900">
          {children}
        </p>
      ),
      blockquote: ({ children }) => (
        <blockquote className="border-l-purple-500">{children}</blockquote>
      ),

      // Ex. 2: rendering custom styles
      customHeading: ({ children }) => (
        <h2 className="text-1xl leading-snug text-gray-800 dark:text-gray-200 md:text-4xl">
          {children}
        </h2>
      ),
    },
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "cursus"][_id == "43df0b7a-64c9-49a3-b3e8-51573db42a98"]{
        title,
        body,
        slug,
        text,
        image,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div className="flex flex-wrap overflow-auto h-screen sm:h-fit pb-24 sm:pb-0 md:h-6/6 -m-4 ">
      <div className="p-4 md:w-1/2">
        <ReactPlayer
          className="react-player fixed-bottom"
          url={fideo}
          width="100%"
          height="100%"
          controls={true}
        />
      </div>
      {allPostsData &&
        allPostsData.map((cursus, index) => (
          // <Link to={"/" + cursus.slug.current} key={cursus.slug.current}>
          <div key={index} className="p-4 md:w-1/2">
            <div className="h-full border-2 border-gray-200 border-opacity-60 bg-gray-100 rounded-lg overflow-hidden ">
              {cursus.mainImage && (
                <img
                  src={urlFor(cursus.mainImage).width(400).url()}
                  className="lg:h-48 md:h-36 w-full object-cover object-center"
                  alt={cursus.title}
                />
              )}
              <div className="p-6">
                <h1 className="title-font text-2xl leading-5 font-medium text-gray-900 mb-3">
                  {cursus.title}
                </h1>
                <h2 className="tracking-widest text-xs title-font font-medium text-gray-900 mb-1">
                  {" "}
                  <PortableText value={cursus.text} components={components} />
                </h2>

                <div className="flex items-center flex-wrap ">
                  <a
                    href={cursus.body}
                    className="h-10 px-5 text-white bg-gradient-to-r bg-kasiagreen-default hover:bg-kasiayellow-default hover:text-white py-2 px-4 rounded mr-2"
                  >
                    Cursus Kopen
                    <svg
                      className="w-4 h-4 ml-2 inline-block"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          //  </Link>
        ))}
    </div>
  );
}

/*
<section className="container px-4 py-10 mx-auto lg:h-128 lg:space-x-8 lg:flex lg:items-center">
<span key={index}>
{cursus.mainImage && (
  <img
    src={urlFor(cursus.mainImage).width(200).url()}
    className="w-10 h-10"
    alt={cursus.title}
  />
)}

<PortableText value={cursus.text} components={components} />
<span>
  <p>{cursus.body}</p>
</span>

<span>
  <h2>{cursus.title}</h2>
</span>
</span>
*/
