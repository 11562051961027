import React from "react";
import logo from "../assets/image/kaslogo.png";
import kp from "../assets/image/kp.jpeg";
import logol from "../assets/image/logol.jpg";
import { useInView } from "react-intersection-observer";

const Footerend = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  return (
    <div ref={ref} className={`box end   ${inView ? "actives" : ""}`}>
      <footer className="color1  body-font">
        <div className="color1">
          <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
            <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
              <div className="w-8 h-8 md:w-14 md:h-14 inline-flex items-center justify-center rounded-full bg-white">
                <img className="logo w-5 md:w-10" src={logo} />
              </div>
              <span className="ml-3 text-white text-xl">Logopeda</span>
            </a>
            <p className="text-sm text-white sm:ml-6 sm:mt-0 mt-4">
              © 2022 Logopeda —
              <a
                href="https://nl.linkedin.com/in/kasia-kijewska-35044435"
                rel="noopener noreferrer"
                className="text-white-600 ml-1"
                target="_blank"
              >
                @kasia
              </a>
            </p>
            <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
              <a
                href="https://www.kwaliteitsregisterparamedici.nl/"
                target="_blank"
                className="text-gray-500"
              >
                <img className="logo" src={kp} width="40px" />
              </a>

              <a
                href="https://www.logopedie.nl/"
                target="_blank"
                className="ml-3 text-gray-500"
              >
                <img className="logo" src={logol} width="40px" />
              </a>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};
export { Footerend };
