import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

function Herotwo() {
  const [allPostsData, setAllPosts] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "heroparttwo"]{
          title,
          bodytitel,
          body,
          slug,
          mainImage{
            asset->{
            _id,
            url
          }
        }
      }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);

    setTimeout(() => {
      setLoading(true);
    }, 1000);
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  if (!loading) {
    return <div></div>;
  }
  return (
    <div ref={ref} className={`box   ${inView ? "actives" : ""}`}>
      {allPostsData &&
        allPostsData.map((heroparttwo, index) => (
          //<Link to={"/" + post.slug.current} key={post.slug.current}>
          <span
            className="flex flex-wrap w-full py-48 px-10 relative mb-4"
            key={index}
          >
            {heroparttwo.mainImage && (
              <img
                key={index}
                src={urlFor(heroparttwo.mainImage).width().url()}
                ref={ref}
                className={`box w-full object-cover h-full object-center block absolute inset-0 ${
                  inView ? "actives" : ""
                }`}
                alt={heroparttwo.title}
              />
            )}

            <div className="text-center relative z-10 w-full">
              <h2 className="text-base md:text-2xl text-white font-medium title-font mb-2">
                {heroparttwo.title}
              </h2>
              {/* <p className="leading-relaxed">{heroparttwo.body}</p> */}
            </div>
          </span>
          // </Link>
        ))}
    </div>
  );
}
export default Herotwo;
