import React, { useReducer, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Joi = require("joi");
const recaptchaRef = React.createRef();
const Contactaanmelden = (props) => {
  const [allPostsData, setAllPosts] = useState(null);
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({
    name: "",
    birth: "",
    adres: "",
    email: "",
    telefoon: "",
    huisarts: "",
    reason: "",
    preferday: "",
    message: "",
    messagex: "",
  });
  const initialFormErrorState = {
    nameError: "",
    birthError: "",
    adresError: "",
    emailError: "",
    telefoonError: "",
    huisartsError: "",
    reasonError: "",
    preferdayError: "",
    messageError: "",
    messagexError: "",
  };

  const reducer = (state, action) => {
    return {
      ...state,
      [action.name]: action.value,
    };
  };

  const [state, dispatch] = useReducer(reducer, initialFormErrorState);

  const objectSchema = {
    name: Joi.string().min(3).required().messages({
      "string.base": "'Naam' moet een string zijn",
      "string.empty": "'Naam' mag niet leeg zijn",
      "string.min": "'Naam' lengte moet minstens {{#limit}} tekens lang zijn",
      "any.required": `'Naam' is een verplicht veld`,
    }),
    birth: Joi.date().min("2001-01-01").required().messages({
      "date.base": "'Geboortedatum' ingevoerd worden",
      "date.empty": "'Geboortedatum' mag niet leeg zijn",
      "date.min": "'Geboortedatum' moet vanaf 01-01-2001 ingevoegd worden",
      "any.required": `'Geboortedatum' is een verplicht veld`,
    }),
    adres: Joi.string().min(3).required().messages({
      "string.base": "'Adres' moet een string zijn",
      "string.empty": "'Adres' mag niet leeg zijn",
      "string.min": "'Adres' lengte moet minstens {{#limit}} tekens lang zijn",
      "any.required": `'Adres' is een verplicht veld`,
    }),

    email: Joi.string()
      .email({
        minDomainSegments: 2,
        tlds: { allow: ["com", "nl", "pl"] },
      })
      .messages({
        "string.base": "'Email' moet een string zijn",
        "string.empty": "'Email' mag niet leeg zijn",
        "string.email": "'Email' moet een geldig e-mailadres zijn",
        "string.min":
          "'Email' lengte moet minstens {{#limit}} tekens lang zijn",
        "any.required": `'Email' is een verplicht veld`,
      }),

    telefoon: Joi.string()
      .min(10)
      .regex(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/)
      .required()
      .messages({
        "string.pattern.base":
          '"Telefoon" met waarde {:[.]} is niet een geldig telefoonnummer',
        "string.base": "'Telefoon' moet een string zijn",
        "string.empty": "'Telefoon' mag niet leeg zijn",
        "string.min":
          "'Telefoon' lengte moet minstens {{#limit}} tekens lang zijn",
        "any.required": `'Telefoon' is een verplicht veld`,
      }),
    huisarts: Joi.string().min(3).required().messages({
      "string.base": "'Huisarts' moet een string zijn",
      "string.empty": "'Huisarts' mag niet leeg zijn",
      "string.min":
        "'Huisarts' lengte moet minstens {{#limit}} tekens lang zijn",
      "any.required": `'Huisarts' is een verplicht veld`,
    }),
    reason: Joi.string().min(3).required().messages({
      "string.base": "'Rede van aanmelding' moet een string zijn",
      "string.empty": "'Rede van aanmelding' mag niet leeg zijn",
      "string.min":
        "'Rede van aanmelding' lengte moet minstens {{#limit}} tekens lang zijn",
      "any.required": `'Rede van aanmelding' is een verplicht veld`,
    }),
    preferday: Joi.string().min(3).required().messages({
      "string.base": "'Voorkeur dag/tijd' moet een string zijn",
      "string.empty": "'Voorkeur dag/tijd' mag niet leeg zijn",
      "string.min":
        "'Voorkeur dag/tijd' lengte moet minstens {{#limit}} tekens lang zijn",
      "any.required": `'Voorkeur dag/tijd' is een verplicht veld`,
    }),

    messagex: Joi.string().messages({
      "string.base": "'Naam' moet een string zijn",
      "string.empty": "'Naam' mag niet leeg zijn",
      "string.min": "'Naam' lengte moet minstens {{#limit}} tekens lang zijn",
      "any.required": `'Naam' is een verplicht veld`,
    }),
  };

  const handleChange = (e, errorFieldName) => {
    setUserData((currentData) => {
      return {
        ...currentData,
        [e.target.id]: e.target.value,
      };
    });
    const propertySchema = Joi.object({
      [e.target.id]: objectSchema[e.target.id],
    });

    const result = propertySchema.validate({ [e.target.id]: e.target.value });
    result.error == null
      ? dispatch({
          name: errorFieldName,
          value: "",
        })
      : dispatch({
          name: errorFieldName,
          value: result.error.details[0].message,
        });
  };

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  const [status, setStatus] = useState("Verzenden");
  const [resulta, setResulta] = useState("");

  const handleSubmit = async (e, nameError) => {
    e.preventDefault();

    /*
    const schema = Joi.object({
      name: Joi.string().alphanum().min(3).max(30).required(),

      email: Joi.string().email({
        minDomainSegments: 2,
        tlds: { allow: ["com", "net"] },
      }),

      message: Joi.string().alphanum().min(3).max(30).required(),
    });
*/
    const details = {
      name: userData.name,
      birth: userData.birth,
      adres: userData.adres,
      email: userData.email,
      telefoon: userData.telefoon,
      huisarts: userData.huisarts,
      reason: userData.reason,
      preferday: userData.preferday,
    };

    const propertySchema = Joi.object(objectSchema);

    const validation = propertySchema.validate(details, { abortEarly: false });

    if (validation.error) {
      const many = validation.error.details.length;

      const step = "";
      for (let step = 0; step < many; step++) {
        // Runs 5 times, with values of step 0 through 4.

        if (validation.error.details[step].context["label"] === "name") {
          dispatch({
            name: "nameError",
            value: validation.error.details[step].message,
          });
        }
        if (validation.error.details[step].context["label"] === "birth") {
          dispatch({
            name: "birthError",
            value: validation.error.details[step].message,
          });
        }
        if (validation.error.details[step].context["label"] === "adres") {
          dispatch({
            name: "adresError",
            value: validation.error.details[step].message,
          });
        }
        if (validation.error.details[step].context["label"] === "email") {
          dispatch({
            name: "emailError",
            value: validation.error.details[step].message,
          });
        }
        if (validation.error.details[step].context["label"] === "telefoon") {
          dispatch({
            name: "telefoonError",
            value: validation.error.details[step].message,
          });
        }
        if (validation.error.details[step].context["label"] === "huisarts") {
          dispatch({
            name: "huisartsError",
            value: validation.error.details[step].message,
          });
        }
        if (validation.error.details[step].context["label"] === "reason") {
          dispatch({
            name: "reasonError",
            value: validation.error.details[step].message,
          });
        }
        if (validation.error.details[step].context["label"] === "preferday") {
          dispatch({
            name: "preferdayError",
            value: validation.error.details[step].message,
          });
        }
      }
    }
    /*
    if (validation.error) {
      dispatch({
        name: "nameError",
        value: validation.error.details[0].message,
      });
      dispatch({
        name: "emailError",
        value: validation.error.details[1].message,
      });
      dispatch({
        name: "messageError",
        value: validation.error.details[2].message,
      });
    }


    if (userData.messagex.trim().length !== 0) {
      console.log("input value is NOT empty");
    } else {
      console.log("input value is empty");
    }
    */
    recaptchaRef.current.execute();
    setStatus("Verzenden");
    console.log(userData.messagex.trim().length);
    if (!validation.error && userData.messagex.trim().length === 0) {
      setStatus("Bezig met verzenden...");
      let response = await fetch("https://grootbroer.nl/aanmelden", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(userData),
      });
      setStatus("Verzenden");

      const result = await response.json();
      if (result.status === "Message Sent")
        return (
          setUserData({
            name: "",
            birth: "",
            adres: "",
            email: "",
            telefoon: "",
            huisarts: "",
            reason: "",
            preferday: "",
            messagex: "",
          }),
          setResulta(
            "Bedankt voor je bericht. Ik zal binnen 7 dagen contact met je opnemen"
          )
        );
    }
  };

  return (
    <div>
      <form id="contact" onSubmit={handleSubmit}>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey="6LdQSk4UAAAAAMAZF00H4JSpBoGEdrsR2HQ3Rdyg"
          onChange={onChange}
        />
        <label className=" block mb-6">
          <span className="text-white">Naam</span>
          <input
            type="text"
            id="name"
            className="
                block
                w-full
                mt-1
                border-gray-300
                shadow-sm
                focus:bg-white
                active:bg-white
 
              "
            placeholder="Voor- en achternaam invoeren"
            onChange={(value) => handleChange(value, "nameError")}
            value={userData.name}
          />
          {state.nameError ? (
            <div
              className="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300"
              role="alert"
            >
              <span className="block sm:inline">{state.nameError}</span>
            </div>
          ) : (
            " "
          )}
        </label>
        <label className=" block mb-6">
          <span className="text-white">Geboortedatum</span>
          <input
            name="birth"
            type="date"
            id="birth"
            className="
                block
                w-full
                mt-1
                border-gray-300
                shadow-sm
                focus:bg-white
                active:bg-white
 
              "
            placeholder="Geboortedatum invoeren"
            onChange={(value) => handleChange(value, "birthError")}
            value={userData.birth}
          />
          {state.birthError ? (
            <div
              className="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300"
              role="alert"
            >
              <span className="block sm:inline">{state.birthError}</span>
            </div>
          ) : (
            " "
          )}
        </label>
        <label className=" block mb-6">
          <span className="text-white">Adres</span>
          <input
            name="adres"
            type="text"
            id="adres"
            className="
                block
                w-full
                mt-1
                border-gray-300
                shadow-sm
                focus:bg-white
                active:bg-white
 
              "
            placeholder="Adres invoeren"
            onChange={(value) => handleChange(value, "adresError")}
            value={userData.adres}
          />
          {state.adresError ? (
            <div
              className="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300"
              role="alert"
            >
              <span className="block sm:inline">{state.adresError}</span>
            </div>
          ) : (
            " "
          )}
        </label>

        <label className="block mb-6">
          <span className="text-white">Email</span>
          <input
            name="email"
            id="email"
            type="email"
            className="
        block
        w-full
        mt-1
        border-gray-300
        shadow-sm
        focus:bg-white
        active:bg-white
      "
            placeholder="Emailadres invoeren"
            onChange={(value) => handleChange(value, "emailError")}
            value={userData.email}
          />
          {state.emailError ? (
            <div
              className="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300"
              role="alert"
            >
              <span className="block sm:inline">{state.emailError}</span>
            </div>
          ) : (
            " "
          )}
        </label>
        <label className="block mb-6">
          <span className="text-white">Telefoon</span>
          <input
            name="telefoon"
            id="telefoon"
            type="tel"
            className="
        block
        w-full
        mt-1
        border-gray-300
        shadow-sm
        focus:bg-white
        active:bg-white
      "
            placeholder="Telefoon invoeren"
            onChange={(value) => handleChange(value, "telefoonError")}
            value={userData.telefoon}
          />
          {state.telefoonError ? (
            <div
              className="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300"
              role="alert"
            >
              <span className="block sm:inline">{state.telefoonError}</span>
            </div>
          ) : (
            " "
          )}
        </label>
        <label className="block mb-6">
          <span className="text-white">Uw huisarts</span>
          <input
            name="huisarts"
            type="text"
            id="huisarts"
            className="
                block
                w-full
                mt-1
                border-gray-300
                shadow-sm
                focus:bg-white
                active:bg-white
 
              "
            placeholder="Wie is uw huisarts?"
            onChange={(value) => handleChange(value, "huisartsError")}
            value={userData.huisarts}
          />
          {state.huisartsError ? (
            <div
              className="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300"
              role="alert"
            >
              <span className="block sm:inline">{state.huisartsError}</span>
            </div>
          ) : (
            " "
          )}
        </label>
        <label className="block mb-6">
          <span className="text-white">Reden van uw aanmelding</span>
          <textarea
            name="reason"
            id="reason"
            className="
            block
            w-full
            mt-1
            border-gray-300
            shadow-sm
            focus:bg-white
            active:bg-white
          "
            rows="3"
            placeholder="Wat is de reden van u aanmelding?"
            onChange={(value) => handleChange(value, "reasonError")}
            value={userData.reason}
          ></textarea>
          {state.reasonError ? (
            <div
              className="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300"
              role="alert"
            >
              <span className="block sm:inline">{state.reasonError}</span>
            </div>
          ) : (
            " "
          )}
        </label>

        <label className="block mb-6">
          <span className="text-white">Voorkeur voor dag/tijd</span>
          <input
            name="preferday"
            type="text"
            id="preferday"
            className="
                block
                w-full
                mt-1
                border-gray-300
                shadow-sm
                focus:bg-white
                active:bg-white
 
              "
            placeholder="Welke dag en tijd is u voorkeur?"
            onChange={(value) => handleChange(value, "preferdayError")}
            value={userData.preferday}
          />
          {state.preferdayError ? (
            <div
              className="p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300"
              role="alert"
            >
              <span className="block sm:inline">{state.preferdayError}</span>
            </div>
          ) : (
            " "
          )}
        </label>
        <label htmlFor="messagex">Messagex </label>
        <input
          id="messagex"
          name="messagex"
          size="40"
          type="text"
          onChange={handleChange}
        />

        <div className="mb-6">
          <button
            type="submit"
            className="
        h-10
        px-5
        text-white bg-gradient-to-r bg-kasiagreen-default hover:bg-kasiayellow-default hover:text-white py-2 px-4 rounded mr-2"
            //</div>  disabled={
            //  !userData.name || !userData.email || !userData.message
            // bg-gradient-to-r from-kasiagreen-default to-kasiayellow-default hover:from-kasiayellow-default hover:to-kasiagreen-default
            // bg-kasiabutton-default hover:bg-kasiagreen-default
            //}
          >
            {status}
          </button>
        </div>
        <div>
          <p className="mt-4 text-lg text-white dark:text-gray-300">
            {resulta}
          </p>
        </div>
      </form>
    </div>
  );
};

export default Contactaanmelden;
