import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import Testimonialone from "./testimonialspart/testimonialpartone";
import Testimonialtwo from "./testimonialspart/testimonialparttwo";
import Testimonialthree from "./testimonialspart/testimonialpartthree";
function Testimonialstwo() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 1000);
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  if (!loading) {
    return <div></div>;
  }

  return (
    <div>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="p-4 lg:w-1/3">
              <Testimonialone />
            </div>
            <div className="p-4 lg:w-1/3">
              <Testimonialtwo />
            </div>

            <div className="p-4 lg:w-1/3">
              <Testimonialthree />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Testimonialstwo;
