import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import Vimeos from "./Vimeos";

function Vimeomoves() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 1000);
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  if (!loading) {
    return <div></div>;
  }
  return (
    <div ref={ref} className={`box ${inView ? "actives" : ""}`}>
      <Vimeos />
    </div>
  );
}
export default Vimeomoves;
