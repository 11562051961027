import React from "react";
import Allblocks from "./Allblocks.js";
import Allsblock from "./Allsblock.js";
import Video from "./Video.js";
import Bink from "./Bink.js";
import Vimeos from "./Vimeos.js";
import ContactForm from "./ContactForm";
import Header from "./Header.js";
import { Googlemaps } from "./Googlemaps.js";
import { Footer } from "./footer.js";
import { Footerend } from "./Footerend.js";
import Hero from "./Hero.js";
import Testimonialstwo from "./Tesimonialtwo.js";
import Vimeomoves from "./Vimeomove.js";
import Footerimage from "./Footerimage.js";
import Cursus from "./Cursus.js";

export default function All() {
  return (
    <React.Fragment>
      <Header />
      <Vimeomoves />
      <Hero />
      <ContactForm />
      <Testimonialstwo />
      <Cursus />
      <Googlemaps />
      <Footer />
      <Footerimage />
      <Bink />
      <Footerend />
    </React.Fragment>
  );
}
