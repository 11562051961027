import React, { useEffect, useState } from "react";
import call from "../../assets/image/phone.svg";
import { useInView } from "react-intersection-observer";
import sanityClient from "../../client.js";

function Fpartthree() {
  const [allPostsData, setAllPosts] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "tel"]{
        title,
        body,
        slug
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);

    setTimeout(() => {
      setLoading(true);
    }, 1000);
  }, []);
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  if (!loading) {
    return <div></div>;
  }
  return (
    <div ref={ref} className={`box ${inView ? "actives" : ""}`}>
      <div className="color1 w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5 flex-shrink-0">
        <a href="tel:06 14734642" className="hover:invert" target="_blank">
          <img className="callme" src={call} width="50px" />
        </a>
      </div>
      <div className="flex-grow">
        {allPostsData &&
          allPostsData.map((tel, index) => (
            //<Link to={"/" + post.slug.current} key={post.slug.current}>
            <span key={index}>
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                {tel.title}
              </h2>

              <p className="leading-relaxed text-base">{tel.body}</p>
            </span>
            // </Link>
          ))}
      </div>
    </div>
  );
}
export default Fpartthree;
