import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import logo from "../assets/image/kaslogo.png";
import flower from "../assets/image/kasia.png";
import { useInView } from "react-intersection-observer";
import { PortableText } from "@portabletext/react";
import Cursusmodal from "./Cursusmodal.js";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import ScrollTrigger from "react-scroll-trigger";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

function Header() {
  const [allPostsData, setAllPosts] = useState(null);
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const [showPopUp, setShowPopUp] = useState(false);
  const showPopupHandler = () => setShowPopUp(false);

  useEffect(() => {
    setTimeout(() => {
      setShowPopUp(true);
    }, 5000);
    // return () => clearTimeout(timer);
  }, []);

  let popup = null;
  if (showPopUp) {
    popup = (
      <div
        id="section-1"
        className="text-white body-font   overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full "
      >
        <div className="pop container w-11/12 md:w-5/6 xl:lg:w-6/12 px-5 my-10  mx-auto  bg-white rounded-lg shadow dark:bg-gray-700 ">
          <div className="flex items-center justify-between p-4 md:p-5 rounded-t dark:border-gray-600 ">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white ">
              Online Cursus
            </h3>
            <button
              onClick={showPopupHandler}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="default-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <Cursusmodal />;
        </div>
      </div>
    );
  }

  const components = {
    types: {
      image: (props) => (
        <figure>
          <img src={urlFor(props.value.asset).width(200).url()} />
        </figure>
      ),
      h1: ({ props }) => <h1 className="text-2xl">{props}</h1>,
    },
    block: {
      // Ex. 1: customizing common block types
      h1: ({ children }) => <h1 className="text-2xl">{children}</h1>,
      h2: ({ children }) => (
        <h2 className="text-3xl text-white leading-snug dark:text-gray-200 md:text-4xl">
          {children}
        </h2>
      ),
      normal: ({ children }) => (
        <p className="mt-4 text-lg text-gray-500 dark:text-gray-300">
          {children}
        </p>
      ),
      blockquote: ({ children }) => (
        <blockquote className="border-l-purple-500">{children}</blockquote>
      ),

      // Ex. 2: rendering custom styles
      customHeading: ({ children }) => (
        <h2 className="text-3xl leading-snug text-gray-800 dark:text-gray-200 md:text-4xl">
          {children}
        </h2>
      ),
    },
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "header"]{
        title,
        bodytitel,
        body,
        text,
        slug,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div>
      <header className="color1 text-gray-600 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
            <div className=" w-8 h-8 md:w-14 md:h-14  inline-flex items-center justify-center rounded-full bg-white">
              <img className="logo w-5 md:w-10" src={logo} />
            </div>
            <span className="ml-3 text-xl text-white">Logopeda</span>
          </a>
          <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
            <a
              href="mailto:info@logopeda.nl"
              className="mr-5 hover:invert contactItem contactItem__mail"
              target="_blank"
            >
              {" "}
              info@logopeda.nl
            </a>
            <a
              href="tel:06 14734642"
              title="Bel 06 14734642"
              className="mr-5 hover:invert contactItem contactItem__phone"
              target="_blank"
            >
              {" "}
              06 14734642
            </a>
          </nav>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
            <a
              href="https://www.facebook.com/profile.php?id=100054451811032"
              target="_blank"
              className="text-gray-500 hover:invert"
            >
              <svg
                fill="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>

            <a
              href="https://www.instagram.com/kasia_kijewska/"
              target="_blank"
              className="ml-3 text-gray-500 hover:invert"
            >
              <svg
                fill="none"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>
            <a
              href="https://nl.linkedin.com/in/kasia-kijewska-35044435"
              target="_blank"
              className="ml-3 text-gray-500 hover:invert"
            >
              <svg
                fill="white"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                ></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a>
          </span>
        </div>
      </header>

      <section className="text-gray-600 body-font ">
        {popup}

        {allPostsData &&
          allPostsData.map((header, index) => (
            <div
              key={index}
              className="container px-5 py-24 mx-auto flex flex-wrap "
            >
              <div
                ref={ref}
                className={` box flex flex-wrap w-full ${
                  inView ? "actives" : ""
                }`}
              >
                <div className="lg:w-2/5  md:pr-10 md:py-6">
                  <div className="flex relative pb-12">
                    <div className=" lg:flex-grow lg:w-1/2  xl:pr-24  flex flex-col md:items-start md:text-left mb-0 items-center text-center">
                      <img className="logo" src={flower} width="140px" />
                      <h2 className="text-3xl leading-snug text-gray-800 dark:text-gray-200 md:text-4xl">
                        {header.bodytitel}
                      </h2>
                      <p className="mt-4 text-lg text-gray-500 dark:text-gray-300">
                        {" "}
                        {header.body}
                      </p>
                      <PortableText
                        value={header.text}
                        components={components}
                      />
                    </div>
                  </div>
                </div>

                {header.mainImage && (
                  <img
                    src={urlFor(header.mainImage).width().url()}
                    className="lg:w-3/5 md:w-1/2 object-cover object-center md:mt-0 mt-12"
                    alt={header.title}
                  />
                )}
              </div>
            </div>
          ))}
      </section>
    </div>
  );
}

export default Header;
