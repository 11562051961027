import React, { useReducer, useState, useEffect } from "react";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import { PortableText } from "@portabletext/react";
import { useInView } from "react-intersection-observer";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

function Bink() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const [allPostsData, setAllPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vacature, setVacature] = useState("Vacature logopodist ");

  const components = {
    types: {
      image: (props) => (
        <figure>
          <img src={urlFor(props.value.asset).width(720).url()} />
        </figure>
      ),
      h1: ({ props }) => <h1 className="text-2xl">{props}</h1>,
    },
    block: {
      // Ex. 1: customizing common block types
      h1: ({ children }) => <h1 className="text-2xl">{children}</h1>,
      h2: ({ children }) => (
        <h2 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
          {children}
        </h2>
      ),
      blockquote: ({ children }) => (
        <blockquote className="border-l-purple-500">{children}</blockquote>
      ),

      // Ex. 2: rendering custom styles
      customHeading: ({ children }) => (
        <h2 className="text-3xl leading-snug text-gray-800 dark:text-gray-200 md:text-4xl">
          {children}
        </h2>
      ),
    },
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "vacature"]{
        title,
        body,
        slug,
        text,
        image,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);

    setTimeout(() => {
      setLoading(true);
    }, 1000);
  }, []);

  //console.log(allPostsData.length);
  if (allPostsData.length < 0) return <div></div>;

  return (
    <div
      className={
        "vacature py-6 color1 border-b border-white " +
        (allPostsData.length > 0 ? "show" : "hidden")
      }
    >
      <div className="text-center mb-2">
        <h2 className="tsm:text-3xl text-2xl font-medium title-font text-white mb-4 ">
          {allPostsData.length > 1 ? "vacatures" : "vacature logopedist"}
        </h2>
      </div>
      {allPostsData &&
        allPostsData.map((vacature, index) => (
          // <Link to={"/" + klip.slug.current} key={klip.slug.current}>
          <span key={index}>
            <section className="text-gray-600 body-font">
              <div className="container vacature mx-auto bg-gray-100 flex px-5 py-5 md:flex-row flex-col rounded-lg items-center">
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                  {vacature.mainImage && (
                    <img
                      src={urlFor(vacature.mainImage).width(720).url()}
                      className=""
                      alt={vacature.title}
                    />
                  )}
                </div>
                <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                  <PortableText value={vacature.text} components={components} />
                  <h2 className="title-font sm:text-3xl text-3xl mb-4 font-medium text-gray-900">
                    {vacature.body}
                  </h2>
                  <div className="flex justify-center">
                    <a
                      href="mailto:info@logopeda.nl"
                      className="inline-flex items-center ml-4  sm:text-3xl text-3xl mb-4 font-medium text-kasiabutton-default"
                    >
                      mail mij
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        className="w-10 h-10 ml-2"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </span>
        ))}
    </div>
  );
}
export default Bink;
